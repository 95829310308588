/* istanbul ignore file */
import {CustomCssVarsFn} from '@wix/yoshi-flow-editor';
import {Alignment} from '../../productPage/constants';

type CustomCssVarsFnParams = Omit<Parameters<CustomCssVarsFn>[0], 'tpaData'>;

export const customCssVars = ({styleParams, isRTL}: CustomCssVarsFnParams) => {
  const getProductDetailsFlexAlignment = () => {
    switch (styleParams.strings.productPage_productDetailsAlignment?.value || Alignment.LEFT) {
      case Alignment.LEFT:
        return 'flex-start';
      case Alignment.RIGHT:
        return 'flex-end';
      case Alignment.CENTER:
        return 'center';
    }
  };

  return {
    productDetailsFlexAlignment: getProductDetailsFlexAlignment(),
    imageGalleryPaginationDotsDirection: isRTL ? 'row-reverse' : 'row',
  };
};
